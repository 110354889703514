/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import {
  Text as UIFoundationsText,
  TextHtmlTags,
  TextHyphens
} from '@dcxwam/dcx-wam-ui-foundations'
import {
  getTextAlignmentMappedProp,
  getMarginBottomMappedProp,
  getTextVariant
} from '../../../utils/props-mappings'
import TextEditConfig from './TextEditConfig'

const Text = ({ ...props }) => {
  const {
    text,
    richText,
    textAlign,
    hyphenation,
    paraFormat,
    textVariant,
    marginBottom,
    theme,
    id,
    ...rest
  } = props.model
  const editableProps = { ...props, model: { ...rest } }

  let component = TextHtmlTags.P
  switch (paraFormat) {
    case 'p':
      component = TextHtmlTags.P
      break
    case 'h1':
      component = TextHtmlTags.H1
      break
    case 'h2':
      component = TextHtmlTags.H2
      break
    case 'h3':
      component = TextHtmlTags.H3
      break
    case 'h4':
      component = TextHtmlTags.H4
      break
    case 'h5':
      component = TextHtmlTags.H5
      break
    case 'h6':
      component = TextHtmlTags.H6
      break
    default:
      break
  }
  const hyphens = hyphenation ? TextHyphens.Auto : TextHyphens.None
  const variant = getTextVariant(textVariant)
  const alignment = getTextAlignmentMappedProp(textAlign)
  const configAtom = {
    content: text,
    isHtml: richText,
    component,
    variant,
    hyphens,
    textTheme: theme,
    horizontalAlignment: alignment,
    id
  }

  const configSection = {
    hasMarginBottom: getMarginBottomMappedProp(marginBottom),
    textHorizontalAlignment: alignment
  }

  return (
    <EditableComponent {...editableProps}>
      {text ? (
        <UIFoundationsText.TextSection {...configSection}>
          <UIFoundationsText {...configAtom} />
        </UIFoundationsText.TextSection>
      ) : (
        TextEditConfig.emptyLabel
      )}
    </EditableComponent>
  )
}

export default Text
